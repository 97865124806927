<template>
  <div class="Home">
    <page-header/>
    <section class="uk-section uk-padding-remove">
      <div class="uk-container">
        <table class="uk-table uk-table-responsive uk-margin-small-bottom">
          <colgroup>
            <col style="width: 20%">
            <col style="width: 35%">
            <col style="width: 20%">
            <col style="width: 35%">
          </colgroup>
          <tbody class="uk-text-center">
          <tr>
            <td class="qtitles">{{$t("orgaExam.orgaList.eName")}}</td>
            <td class="qsubTitles"><input type="text" :placeholder="$t('examName')"
                                          class="uk-input" v-model="exam.examName"/></td>
            <td class="qtitles">{{$t("orgaExam.orgaList.eType")}}</td>
            <td class="qsubTitles">
              <div class="uk-margin uk-grid-small uk-child-width-auto uk-grid
                                uk-flex uk-flex-center uk-flex-middle">
                <h5 style="font-size: 14px">
                  <input class="uk-radio" type="radio" name="examType" v-model="exam.examType"
                         value="public"/> {{$t("public")}}
                </h5>
                <h5 style="font-size: 14px">
                  <input class="uk-radio" type="radio" name="examType" v-model="exam.examType"
                         value="private"/> {{$t("private")}}
                </h5>
              </div>
            </td>
          <tr>
            <td class="qtitle">{{$t("orgaExam.orgaList.eLifeTime")}}
            </td>
            <td class="qsubTitle" colspan="3">
              <date-picker
                  class="date-picker"
                  v-model="exam.startDate"
                  :lang="lang"
                  :placeholder="$t('startDate')"
              ></date-picker>
              <span uk-icon="icon: arrow-right; ratio: 1.5"
              ></span>
              <date-picker
                  class="date-picker"
                  v-model="exam.endDate"
                  :placeholder="$t('endDate')"
                  :lang="lang"
              ></date-picker>
            </td>
<!--            <td class="qtitle">{{$t("orgaExam.orgaList.eFee")}}</td>-->
<!--            <td class="qsubTitle">-->
<!--              <div class="uk-margin uk-grid-small uk-child-width-auto uk-grid-->
<!--                                uk-flex uk-flex-center uk-flex-middle">-->
<!--                <h5 style="font-size: 14px">-->
<!--                  <input class="uk-radio" type="radio" name="radio2"-->
<!--                         v-model="exam.examFee"  value=""/> {{$t("paid")}}-->
<!--                </h5>-->
<!--                <h5 style="font-size: 14px">-->
<!--                  <input class="uk-radio" type="radio" name="radio2"-->
<!--                         v-model="exam.examFee"-->
<!--                         value="1"/> {{$t("free")}}-->
<!--                </h5>-->
<!--              </div>-->
<!--            </td>-->
          </tr>
          </tbody>
        </table>
      </div>
    </section>
    <section class="uk-section uk-padding-remove">
      <div class="uk-container">
        <div class="uk-width-1-1 uk-margin-remove-left" uk-grid>
          <div class="uk-width-1-4@m"></div>
          <div class="uk-width-1-4@m"></div>
          <div class="uk-width-1-4@m"></div>
          <div class="uk-width-1-4@m uk-flex uk-flex-right">
            <button
                class="qbtn_d uk-button uk-width-1-2@m uk-visible@m"
                @click="searchDefault()">{{ $t("orgaExam.examResultView.default") }}
            </button>
            <button
                class="qbtn_d uk-button uk-width-1-2@m uk-visible@m"
                @click="searchFilter()">{{$t("orgaExam.orgaList.search")}}
            </button>
          </div>
        </div>
      </div>
    </section>
    <section class="uk-section uk-padding-small">
      <div class="uk-container">
        <div class="uk-card uk-card-default uk-box-shadow-medium">
          <table class="uk-table uk-table-striped uk-table-responsive uk-table-small">
            <thead style="background: #01b0ff">
            <tr>
              <th class="uk-text-center ">№</th>
              <th class="uk-text-center">{{$t("orgaExam.orgaList.eName")}}</th>
              <th class="uk-text-center uk-table-strike">{{$t("orgaExam.orgaList.rUser")}}</th>
              <!-- <th class="uk-text-center">Created at</th> -->
              <th class="uk-text-center">{{$t("orgaExam.orgaList.survey")}}</th>
              <th class="uk-text-center">{{$t("orgaExam.orgaList.lTime")}}</th>
              <th class="uk-text-center">{{$t("orgaExam.orgaList.status")}}</th>
              <th colspan="3" class="uk-text-center">{{$t("orgaExam.orgaList.mExam")}}</th>
            </tr>
            </thead>
            <tbody v-if="examDetail.length > 0">
            <tr class="uk-text-center" v-for="(item, index) in examDetail" :key="index">
              <td class="uk-text-middle">
                              <span v-if="currentPage == 0">
                              {{ index + 1 }}
                            </span>
                <span v-else>
                              {{ ((currentPage * size) + index) - size + 1 }}
                            </span>
              </td>
              <td class="uk-text-middle" style="max-width: 140px">
<!--                <a href="#examGroup" uk-toggle>-->
                  {{item.examName}}
<!--                </a>-->
              </td>
              <td class="uk-text-middle">
                <h5 >{{item.regUser}}</h5>
              </td>
              <!-- <td class="uk-text-middle"><h5  v-if="item.ceatedAt == null">null</h5>
                <h5  v-else>{{item.ceatedAt|moment}}</h5>
              </td> -->
              <td class="uk-text-middle">
                <div v-if="item.survey == 1 && (item.regUserId == currentUser.id || currentUser.authorities[0].authority == 'ROLE_ADMIN')">
                  <button type="button" class="uk-button qbtn_q"
                          v-if="item.surveyattach == 0"
                          @click="surveyList(item.examId)">
                    {{$t("choose")}}
                  </button>
                  <button type="button" class="uk-button qbtn_b"
                          v-else @click="surveyList(item.examId)">
                    {{$t("choose")}}
                  </button>
                </div>
                <!--                <div  v-else>-->
                <!--                  <button type="button" class="uk-button qbtn_b uk-width-1-1  uk-button-disable"-->
                <!--                          @click="noSurvey">{{$t("orgaExam.orgaList.select")}}-->
                <!--                  </button>-->
                <!--                </div>-->
              </td>
              <td class="uk-text-middle"><h5 class="uk-margin-remove" v-if="item.examSdate == null">
                null</h5>
                <h5 class="uk-margin-remove" v-else>{{item.examSdate|moment}}</h5> ~ <h5
                    class="uk-margin-remove"
                    v-if="item.examEdate == null">
                  null</h5>
                <h5 class="uk-margin-remove" v-else>{{item.examEdate|moment}}</h5></td>
              <td class="uk-text-middle">
                <h5 class="uk-text-capitalize" v-if="item.examStatus != ''">{{item.examStatus}}</h5>
                <h5 class="uk-text-capitalize" v-else>null</h5>
              </td>
              <td style="width: 120px;">
                <div  v-if="item.regUserId == currentUser.id || currentUser.authorities[0].authority == 'ROLE_ADMIN'">
                  <button type="button" v-if="item.useflag == 'Y' || item.examStatus == 'start'"
                          class="qbtn_b uk-margin-small-top uk-width-1-1 uk-button uk-button-small"
                          @click="questionCheckUrl('exam_check',item.examId, item.variants)">
                    {{$t("orgaExam.orgaList.ques")}}
                  </button>
                  <!--  href="#questionModal" uk-toggle @click="getExamQuestionsSee(item.examId)"-->
                  <span v-else>
                    <router-link v-if="item.isTms != '1'"
                                 :to="{name: 'OrganizationExamManagement', params: { examId: item.examId}}"
                                 class="qbtn_d uk-margin-small-top uk-width-1-1 uk-button uk-button-small">
                    {{$t("orgaExam.orgaList.ques")}}
                    </router-link>
                    <button type="button" v-else
                            class="qbtn_b uk-margin-small-top uk-width-1-1 uk-button uk-button-small"
                            @click="questionCheckUrl('exam_check',item.examId, item.variants)">
                        {{$t("orgaExam.orgaList.ques")}}
                    </button>
                </span>

                  <button type="button" v-on:click="viewExamCodes(item)" v-if="item.isTms != '1'"
                          class="qbtn_d uk-margin-small-top uk-width-1-1 uk-button uk-button-small"
                          href="#modal-examcode" uk-toggle>{{$t("orgaExam.orgaList.examCode")}}
                  </button>
                </div>
                <div v-else>
                    ...
                </div>
              </td>
              <td style="width: 120px;" >
                <div v-if="item.regUserId == currentUser.id || currentUser.authorities[0].authority == 'ROLE_ADMIN'">
                  <div v-show="item.examCodeCount > 0 && item.variants.length > 0">
                    <button type="button" v-on:click="examControl(item)" v-show="item.examStatus == 'prepare'"
                            style="background-color: #7C7877"
                            class="qbtn_d uk-margin-small-top uk-width-1-1 uk-button uk-button-small"
                            href="#modal-examControl" uk-toggle>{{$t("orgaExam.orgaList.control")}}
                    </button>
                    <button type="button" v-on:click="examControl(item)" v-show="item.examStatus == 'start'"
                            style="background-color: #20bf6b"
                            class="qbtn_d uk-margin-small-top uk-width-1-1 uk-button uk-button-small"
                            href="#modal-examControl" uk-toggle>{{$t("orgaExam.orgaList.control")}}
                    </button>
                    <button type="button" v-on:click="examControl(item)" v-show="item.examStatus == 'finish'"
                            style="background-color: #000"
                            class="qbtn_d uk-margin-small-top uk-width-1-1 uk-button uk-button-small"
                            href="#modal-examControl" uk-toggle>{{$t("orgaExam.orgaList.control")}}
                    </button>
                    <button type="button" v-on:click="examControl(item)" v-show="item.examStatus == 'end'"
                            style="background-color: #DC143C"
                            class="qbtn_d uk-margin-small-top uk-width-1-1 uk-button uk-button-small"
                            href="#modal-examControl" uk-toggle>{{$t("orgaExam.orgaList.control")}}
                    </button>
                    <button type="button" v-on:click="examControl(item)" v-show="item.examStatus == 'ready'"
                            class="qbtn_d uk-margin-small-top uk-width-1-1 uk-button uk-button-small"
                            href="#modal-examControl" uk-toggle>{{$t("orgaExam.orgaList.control")}}
                    </button>
                  </div>
                  <router-link :to="{name: 'OrganizationExamineeResultView', params: {
                                        examId: item.examId}}"
                               class="qbtn_d uk-margin-small-top uk-width-1-1 uk-button uk-button-small">
                    {{$t("orgaExam.orgaList.result")}}
                  </router-link>
                  <router-link :to="{name: 'ExamGroupMapping', params: {
                                        examId: item.examId}}"
                               class="qbtn_d uk-margin-small-top uk-width-1-1 uk-button uk-button-small">
                    {{$t("addStu")}}
                  </router-link>
                </div>
                <div v-else>
                  ...
                </div>
              </td>
              <td style="width: 38px;">
                <!--                <template v-for="(permission,perIndex) in currentUser.authorities">-->
                <div  v-if="item.regUserId == currentUser.id || currentUser.authorities[0].authority == 'ROLE_ADMIN'">
                  <router-link tag="a" :to="{name: 'OrganizationExamRegister', params: {
                                        examId: item.examId}}"
                               uk-icon="icon: file-edit; ratio: 1.5">
                  </router-link>
                  <a class="uk-text-danger" uk-icon="icon: trash; ratio:1.5"
                     href="#deletePop" uk-toggle @click="deleteexamId(item.examId)"></a>
                </div>
                <!--                </template>-->
              </td>
            </tr>
            </tbody>
            <tbody v-else>
            <tr >
              <td class="uk-alert-primary" uk-alert colspan="11">
                <p class="uk-text-center">{{$t("orgaExam.orgaList.notFound")}}</p>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <!-- attach model -->
        <div id="attach_modal" uk-modal>
          <div class="uk-modal-dialog uk-modal-body qList" style="width: 720px;">
            <h3 class="uk-text-center">
              {{$t("orgaExam.orgaList.sList")}}</h3>
            <div class="uk-card uk-card-body  uk-padding-remove" style="margin-bottom: 20px;">
              <table class="uk-table uk-table-responsive uk-table-striped uk-text-capitalize uk-text-center">
                <thead  style="background: #01b0ff">
                <tr>
                  <th >№</th>
                  <th class="uk-text-capitalize uk-text-center">{{$t("orgaExam.orgaList.name")}}</th>
                  <th class="uk-text-capitalize uk-text-center">{{$t("orgaExam.orgaList.content")}}</th>
                  <th style="min-width: 40px" class="uk-text-capitalize uk-text-center">{{$t("surveyhead1")}}</th>
                  <th class="uk-text-capitalize uk-text-center">{{$t("surveyhead2")}}</th>
                  <th class="uk-text-capitalize uk-text-center"></th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item,surIndex) in surDetails" :key="surIndex">
                  <td >{{surIndex+1}}</td>
                  <td >{{item.title}}</td>
                  <td class="uk-text-left">{{item.memo}}</td>
                  <td >{{item.qCount}}</td>
                  <td>
                    <button style="min-width: 80px" type="button" class="uk-button uk-button-default uk-button-small"
                            uk-toggle="target: #detailsModel" @click="surveyDetail(item.id)">
                      {{$t("survey.details")}}
                    </button>
                  </td>
                  <td v-if="item.attached == false">
                    <button style="min-width: 80px" type="button" class="uk-button uk-button-primary uk-button-small "
                            @click="attachSur(item.id,surIndex)">{{$t("orgaExam.orgaList.attach")}}
                    </button>
                  </td>
                  <td v-else>
                    <button style="min-width: 80px" type="button" class="uk-button uk-button-danger uk-button-small"
                            @click="unAttModel(item.id,surIndex)">{{$t("orgaExam.orgaList.unAttach")}}
                      <!--                                              @click="unAttachSur(item.id,surIndex)"-->
                    </button>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
            <p class="uk-text-right uk-margin-remove">
              <button type="button" class="uk-button uk-button-default uk-modal-close"
              >{{$t("btnCancel")}}
              </button>
            </p>
          </div>
        </div>
        <!-- Delete pop -->
        <div id="deletePop" class="uk-flex-top" uk-modal>
          <div class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
            <button class="uk-modal-close-default"  uk-close></button>
            <span class="uk-flex uk-flex-center" uk-icon="icon:info; ratio:3.5"></span>
            <p class="uk-modal-title uk-text-center">
              {{$t("orgaExam.orgaList.delete")}}
            </p>
            <div class="uk-width-1-1 uk-margin-remove-left" uk-grid>
              <div class="uk-width-1-2@m uk-flex uk-flex-center">
                <button type="button" class="uk-button uk-button-danger uk-modal-close"
                        @click="deletesExam(item.examId)">
                  {{$t("delete")}}
                </button>
              </div>
              <div class="uk-width-1-2@m uk-flex uk-flex-center">
                <button type="button" class="uk-button uk-button-default uk-modal-close">
                  {{ $t('cancel') }}
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- End pop -->

        <div id="askingPop" class="uk-flex-top" uk-modal>
          <div class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
            <button class="uk-modal-close-default"  uk-close></button>
            <span class="uk-flex uk-flex-center" uk-icon="icon:info; ratio:2">
                  </span>
            <p class="uk-text-bold uk-padding-small uk-text-center">
              {{$t("unattachconfirm")}}
            </p>
            <div class="uk-width-1-1 uk-margin-remove-left" uk-grid>
              <div class="uk-width-1-2@m" style="margin: auto;display: flex;justify-content: space-between;">
                <button type="button" class="uk-button uk-button-primary uk-modal-close"
                        @click="unAttachSur()">
                  {{$t("btnUnAttach")}}
                </button>
                <button style="min-width: 100px" type="button" class="uk-button uk-button-default uk-modal-close">
                  {{ $t('cancel') }}
                </button>
              </div>
            </div>
          </div>
        </div>

        <!-- details model -->
        <div id="detailsModel" uk-modal>
          <div class="uk-modal-dialog">
            <button class="uk-modal-close-default"  uk-close></button>
            <div class="uk-modal-header uk-margin-bottom">
              <h2 class="uk-modal-title uk-text-center">{{surDetails.title}}</h2>
            </div>
            <div class="uk-modal-body uk-padding-remove-top" style="max-height: 600px; overflow: auto"
                 v-for="(question,qindex) in surDetails.questions" :key="qindex">
              <div class="uk-grid uk-padding uk-border-rounded uk-box-shadow-medium uk-margin-remove-left">
                <div class="uk-width-auto uk-padding-remove" style="align-self: center;">
                  <h1>{{qindex+1}}</h1>
                </div>
                <div class="uk-width-expand">
                  <p class="sur-ques">{{question.question}}
                    <span v-if="question.type == 0" class="uk-text-primary">{{$t("organization.questionList.choiceA")}}</span>
                    <span v-else-if="question.type == 1" class="uk-text-primary">({{$t("organization.questionList.sAnswer")}})</span>
                    <span v-else class="uk-text-primary">{{$t("organization.questionList.choiceA")}}</span>
                  </p>
                  <div class="uk-grid">
                    <span class="uk-child-width-1-4"
                          v-for="(answer,codeIndex) in (question.answers.split('||'))" :key="codeIndex">
                      <span class="surAnswer" v-if="question.answers != ''"><span class="uk-margin-right">{{codeIndex+1}}.</span>{{answer}}</span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="uk-modal-footer uk-text-right">
              <button class="uk-button uk-button-default uk-modal-close"
              >{{ $t("close") }}</button>
            </div>
          </div>
        </div>

        <!-- exam Control model -->
        <div id="modal-examControl" class="uk-flex-top" uk-modal>
          <div class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
            <button class="uk-modal-close-default"  uk-close></button>
            <div class="uk-width-1-1 uk-margin-small uk-margin-remove-left" uk-grid>
              <div class="uk-width-1-2@m uk-padding-small">
                <div align="center" @click="examStatusChange('prepare')">
                  <a class="example_a"
                     rel="nofollow noopener">{{$t("orgaExam.orgaList.esPre")}}</a></div>
              </div>
              <div class="uk-width-1-2@m uk-padding-small ">
                <div align="center" @click="examStatusChange('start')">
                  <a class="example_b"
                     rel="nofollow noopener">{{$t("orgaExam.orgaList.esStart")}}</a></div>
              </div>
            </div>
            <div class="uk-width-1-1 uk-margin-small uk-margin-remove-left " uk-grid>
              <div class="uk-width-1-2@m uk-padding-small">
                <div align="center" @click="examStatusChange('end')">
                  <a class="example_c"
                     rel="nofollow noopener">{{$t("orgaExam.orgaList.esEnd")}}</a></div>
              </div>
              <div class="uk-width-1-2@m uk-padding-small ">
                <div align="center" @click="examStatusChange('finish')">
                  <a class="example_d"
                     target="_blank" rel="nofollow noopener">{{$t("orgaExam.orgaList.esFinish")}}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- end exam control model -->
        <!-- Question model -->
        <div id="questionModal" uk-modal class="uk-modal">
          <div class="uk-modal-dialog uk-modal-body questionDialog qList">
            <button class="uk-modal-close-default"  uk-close></button>
            <h3 class="uk-text-center ">{{$t("orgaExam.orgaList.eQues")}}</h3>
            <div class=" uk-card uk-card-body uk-width-1-1 userQuestionView uk-padding-small ">
              <div  v-for="(items,indexs) in getExamQuestion" :key="indexs">
                <div class="uk-width-1-1@m uk-margin-remove-left" uk-grid>
                  <p class="uk-padding-remove-left uk-margin-remove uk-width-auto@m optionNum">
                    {{indexs+1}}</p>
                  <span class="uk-margin-right uk-margin-remove-bottom questionsSee uk-width-expand@m
                          uk-padding-small-left" v-html="items.question"></span>
                </div>
                <div id="mediaList" class="uk-margin-top uk-margin-bottom">
                  <template v-for="(item, index) in items.datas">
                    <template v-if="item.media != null" width="100%" class="uk-margin-bottom">
                      <img :src="`/uploadingDir/examquestion/${item.media.fileName}`"
                           width="100%" v-if="item.media.mediaType == 'image'"
                           alt="item.media.filename" :key="index"/>
                      <img :src="`/uploadingDir/question/${item.media.filename}`"
                           width="100%" v-else-if="item.media.mediatype == 'stack'"
                           alt="item.media.filename" :key="index"/>
                      <div  v-else-if="item.media.mediaType == 'audio'" :key="index">
                        <vue-plyr data-width="500" data-height="300">
                          <audio>
                            <source :src="`/uploadingDir/examquestion/${item.media.fileName}`"
                                    type="audio/mp3">
                            <source :src="`/uploadingDir/examquestion/${item.media.fileName}`"
                                    type="audio/ogg">
                          </audio>
                        </vue-plyr>
                      </div>
                      <div  v-else :key="index">
                        <vue-plyr data-width="500" data-height="300">
                          <video poster>
                            <source :src="`/uploadingDir/examquestion/${item.media.fileName}`"
                                    type="video/mp4" size="720">
                            <source :src="`/uploadingDir/examquestion/${item.media.fileName}`"
                                    type="video/mp4" size="1080">
                            <track
                                kind="captions"
                                label="English"
                                srclang="en" default>
                          </video>
                        </vue-plyr>
                      </div>
                    </template>
                    <p v-else :key="index" class="data_text" v-html="item.data_text"></p>
                  </template>
                </div>
                <div v-if="items.questionType == 2">
                  <div v-for="(answer, ansIndex) in items.answers" :key="ansIndex">
                    <div class="uk-flex uk-margin-large-left">
                      <span>True data: </span>
                      <span class="onOptions uk-margin-left">{{answer.answerTrueData}}</span>
                    </div>
                    <div class="uk-flex uk-margin-large-left" style="padding-bottom: 20px;">
                      <span>Hint data: </span>
                      <span class="onOptions uk-margin-left">{{answer.answer}}</span>
                    </div>
                  </div>
                </div>
                <table class="v_example uk-width-1-1" width="100%" style="margin-bottom: 30px;" v-else>
                  <tbody>
                  <template v-for="(answer, ansIndex) in items.answers">
                    <tr
                        :class="{ 'onOptions': answer.answerFlag == 'true'}" :key="ansIndex">
                      <th style="width: 35px;"><p class="uk-margin-remove-bottom options">
                        {{answer.optionNumber}}</p></th>
                      <td v-if="answer.media!=null">
                        <img
                            :src="`/uploadingDir/examanswer/${answer.media.fileName}`"
                            :alt="answer.media.fileName"
                            v-if="answer.media.mediaType == 'image'"/>
                        <div  v-else-if="answer.media.mediaType == 'audio'">
                          <vue-plyr data-width="500" data-height="300">
                            <audio>
                              <source :src="`/uploadingDir/examanswer/${answer.media.fileName}`"
                                      type="audio/mp3">
                              <source :src="`/uploadingDir/examanswer/${answer.media.fileName}`"
                                      type="audio/ogg">
                            </audio>
                          </vue-plyr>
                        </div>
                        <div  v-else>
                          <vue-plyr
                              data-width="500"
                              data-height="300">
                            <video poster>
                              <source :src="`/uploadingDir/examanswer/${answer.media.fileName}`"
                                      type="video/mp4" size="720">
                              <source :src="`/uploadingDir/examanswer/${answer.media.fileName}`"
                                      type="video/mp4" size="1080">
                              <track
                                  kind="captions"
                                  label="English"
                                  srclang="en" default>
                            </video>
                          </vue-plyr>
                        </div>
                      </td>
                      <td>
                        <p class="uk-margin-small-left answers uk-margin-small-bottom"
                           v-html="answer.answer"></p>
                      </td>
                    </tr>
                  </template>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="uk-modal-footer uk-text-right">
              <button type="button" class="uk-button uk-button-default uk-modal-close" >Ok</button>
            </div>
          </div>
        </div>
        <!-- end question model -->
        <!-- modal-view section -->
        <div id="modal-examcode" uk-modal class="uk-modal-container">
          <div class="uk-modal-dialog uk-modal-body">
            <button class="uk-modal-close-default"  uk-close></button>
            <div class="uk-width-1-1" uk-grid v-for="(items) in examCodes"
                 :key="items.id">
              <div class="uk-width-2-5@m qList">
                <h4   style="padding-top:0px; padding-bottom: 10px">
                  {{examCodeExamName}}</h4>
              </div>
              <div class="uk-width-1-5@m uk-margin-small-top">{{$t("orgaExam.orgaList.tExamCode")}}:<label
                  class="uk-label uk-margin-small-left">{{items.examcodes.length}}</label>
              </div>
              <div class="uk-width-1-5@m uk-margin-small-top">{{$t("orgaExam.orgaList.uExamCode")}}:
                <label class="uk-label uk-margin-small-left">{{isOrderCount}}</label>
              </div>
              <p class="uk-flex uk-flex-middle uk-flex-center uk-width-1-5@m uk-margin-small-top">
                {{$t("orgaExam.orgaList.variant")}} :
                <label class="uk-margin-small-left uk-label"
                       v-for="(item,index) in variants"
                       :key="index">{{item}}</label>
              </p>
            </div>
            <div class="uk-width-1-1 uk-margin-top uk-flex" style="border-bottom: 1px solid rgb(64, 67, 72);
                                                    padding: 5px 0px;
                                                    margin-right: 10px;">
              <div v-show="showTable == true">
                <button type="button" @click="nextVar('ooo')" v-if="variants.length > 1"
                        class="uk-button uk-button-danger uk-button-small uk-margin-right">
                  {{ $t('setVariants') }}
                </button>
                <button type="button" v-else
                        class="uk-button uk-button-default uk-button-small uk-margin-right" style="border: none; height: 35px;">
                </button>
              </div>
              <div v-show="showTable == false">
                <select class="uk-select uk-form-width-small uk-form-small uk-margin-right uk-flex-right"
                        v-model="setVa">
                  <option value="">All</option>
                  <template v-for="(variant,varIndex) in variants">
                    <option :key="varIndex" :value="variant">
                      {{variant}}
                    </option>
                  </template>
                </select>
                <button type="button" @click="sendVariants()"
                        class="uk-button uk-button-primary uk-button-small uk-margin-right">
                  {{$t('vsendall')}}
                </button>
              </div>
              <button type="button" class="uk-button uk-button-danger uk-button-small"
                      @click="nextVar('ooo')"
                      style="position: absolute; right: 36px;"
                      v-show="showTable == false">{{$t('back')}}
              </button>
              <button type="button" class="uk-button uk-button-danger uk-button-small"
                      v-show="showTable == true"
                      style="position: absolute; right: 36px;"
                      @click="sendAllUserExamCode()">{{$t('sendAll')}}
              </button>
            </div>
            <div class="uk-margin-top">
              <div class="uk-card uk-card-default uk-box-shadow-medium examCodeView">
                <table class="uk-table uk-table-responsive uk-table-striped uk-table-divider uk-table-small uk-text-middle">
                  <thead  style="background: #01b0ff">
                  <tr >
                    <th class="uk-text-center" v-show="showTable == false">
                      <input @click="selVarAll()" class="uk-checkbox" type="checkbox"
                             v-model="checkall" value="true"/>
                    </th>
                    <th class="uk-text-center">No</th>
                    <th class="uk-text-center">{{$t("orgaExam.orgaList.examCode")}}</th>
                    <th class="uk-text-center">{{$t("orgaExam.orgaList.variants")}}</th>
                    <th class="uk-text-center">{{$t("orgaExam.orgaList.status")}}</th>
                    <th class="uk-text-center">{{$t("orgaExam.orgaList.uName")}}</th>
                    <th class="uk-text-center">{{$t("orgaExam.orgaList.uEmail")}}</th>
                    <th class="uk-text-center">{{$t("orgaExam.orgaList.date")}}</th>
                    <th class="uk-text-center" v-show="showTable == true">
                      {{$t("orgaExam.orgaList.send")}}
                    </th>
                  </tr>
                  </thead>
                  <tbody class="uk-text-middle" v-for="(items) in examCodes" :key="items.id">
                  <tr class="uk-text-center" v-for="(item, index) in items.examcodes"
                      :key="index">
                    <td v-show="showTable == false">
                      <input @click="selVar(item)" class="uk-checkbox" type="checkbox"
                             v-if="item.examineeid != null" v-model="item.selected"/>
                      <span v-else></span>
                    </td>
                    <td class="uk-text-middle"><h4 >{{index + 1}}</h4></td>
                    <td class="uk-text-middle"><h5 >{{item.examcode}}</h5></td>
                    <td class="uk-text-middle" v-if="item.examineeid != null">
                      <div class="uk-flex uk-flex-center uk-margin-small-right"
                           v-if="item.variant == null">
                        <h5 :key="index" class="uk-margin-remove "
                            v-for="(item,index) in variants">
                          {{item}},
                        </h5>
                      </div>
                      <div class="uk-flex uk-flex-center" v-else>
                        <h5 class="uk-margin-remove">{{item.variant}}</h5>
                      </div>
                    </td>
                    <td class="uk-text-middle" v-else>
                    </td>
                    <td class="uk-text-middle">
                      <label class="uk-label isorder uk-label-primary"
                             v-if="item.isOrder == true && item.examineeemail == null">
                        Waiting</label>
                      <label class="uk-label isorder uk-label-success"
                             v-else-if="item.isOrder == true && item.examineeemail != null">Purchased</label>
                      <label class="uk-label isorder uk-label-warning"
                             v-else>Pending</label>
                    </td>
                    <td class="uk-text-middle"><h5 >{{item.examineename}}</h5></td>
                    <td class="uk-text-middle">
                      <h5  v-if="item.isOrder == true">{{item.examineeemail}}</h5>
                      <input class="uk-input uk-form-width-medium uk-width-2-3@m" v-else
                             :name="index"
                             v-model="item.examineeemail" style="width:250px"
                             type="text" :placeholder="$t('eaddress')">
                    </td>
                    <td class="uk-text-middle"><h5 >{{item.updateAt|moment}}</h5></td>
                    <td  v-show="showTable == true">
                      <button type="button" class="uk-button uk-button-danger uk-button-small"
                              v-if="item.isOrder == false"
                              @click="sendExamId(item.examineeemail,item.examcode)">send
                      </button>
                      <span v-if="item.isOrder == true">...</span>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="uk-modal-footer uk-text-right">
              <!--              <router-link class="uk-button uk-button-default uk-modal-close" :to="{name: 'OrganizationStudentExamcode',-->
              <!--                        params:{ examId: 0}}">-->
              <!--                Variant selection auto-->
              <!--              </router-link>-->
              <!--              <router-link class="uk-button uk-button-primary uk-modal-close" :to="{name: 'OrganizationStudentExamcode',-->
              <!--                        params:{ examId: 0}}">-->
              <!--                User and valiant selection-->
              <!--              </router-link>-->
              <button type="button" class="uk-button uk-button-default uk-modal-close" >Ok</button>
            </div>
          </div>
        </div>
        <!-- end modal-view section -->

        <div id="variant_show" uk-modal>
          <div class="uk-modal-dialog">
            <button class="uk-modal-close-default"  uk-close></button>
            <div class="uk-modal-header">
              <h2 class="uk-modal-title uk-text-center"
                  style="font-size: 21px; font-weight: 700;">{{ $t('chVariant') }}</h2>
            </div>
            <div class="uk-modal-body uk-text-center">
              <div class="uk-flex uk-flex-center">
                <template v-for="(item,index) in showVariant">
                  <button type="button" :key="index" @click="variantUrl(item)"
                          class="uk-button uk-button-default uk-button-hover uk-margin-right">{{item}}
                  </button>
                </template>
              </div>
            </div>
            <div class="uk-modal-footer uk-text-right">
              <button type="button" class="uk-button uk-button-default uk-button-small uk-modal-close" >
                {{ $t('close') }}
              </button>
            </div>
          </div>
        </div>

        <div id="examGroup" uk-modal>
          <div class="uk-modal-dialog" style="width: 90%;">
            <button class="uk-modal-close-default"  uk-close></button>
            <div class="uk-modal-header">
              <h2 class="uk-modal-title uk-text-center"
                  style="font-size: 21px; font-weight: 700;">Exam Group</h2>
            </div>
            <div class="uk-modal-body uk-text-center">
              <group-data></group-data>
            </div>
            <div class="uk-modal-footer uk-text-right">
              <button type="button" class="uk-button uk-button-default uk-button-small uk-modal-close" >
                {{ $t('close') }}
              </button>
            </div>
          </div>
        </div>
        <!-- end sendExamCode -->
      </div>
    </section>
    <section class="uk-section uk-padding-remove ">
      <div class="uk-container">
        <div class="uk-card-footer uk-text-center">
          <div class="uk-flex uk-flex-center">
            <v-pagination
                v-model="currentPage"
                :page-count="pages"
                :classes="uikitClasses"
                :labels="Labels"
                @change="onChange"
            ></v-pagination>
          </div>
        </div>
      </div>
    </section>
    <page-footer/>
  </div>

</template>
<script>
import {mapGetters} from 'vuex'
import vPagination from "@/components/globals/vue-plain-pagination"
import ExamService from '@/services/ExamService'
import UIkit from 'uikit'
import moment from "moment";
import DatePicker from "vue2-datepicker";
import 'vue2-datepicker/index.css';
import UserService from '@/services/UserService'
import ResearchService from "../../services/ResearchService";
import PageHeader from '@/components/Organizcmp/OrganizHeader.vue'
import GroupData from '@/components/Organizcmp/OrganizStGroupList.vue'

const PageFooter = () => import("../Organizcmp/OrganizFooter.vue");

export default {
  name: "Home",
  components: {
    DatePicker,
    vPagination,
    GroupData,
    PageHeader,
    PageFooter

  },
  computed: {
    ...mapGetters(["isLoggedIn", "getToken", "currentUser"]),
  },
  data() {
    return {
      examCodeCount: 0,
      countries: null,
      selected: [],
      selectAll: false,
      showTable: true,
      size: 12,
      variant: [
        {
          name: ["A"]
        },
        {
          name: ["B"]
        },
        {
          name: ["C"]
        },
        {
          name: ["D"]
        }
      ],
      examId: 0,
      examCodeSend: '',
      examCodes: [],
      agreeType: "",
      description: "",
      payment: "",
      examDate: "",
      examDateEnd: "",
      examDetail: [],
      currentPage: 1,
      pages: 0,
      CurrentExam: [],
      examStatus: '',
      isOrderCount: 0,
      item: {
        emailAddress: null,
      },
      examCodeSendOne: [],
      exam: {
        examName: '',
        examType: '',
        startDate: '',
        endDate: '',
        examFee: '',
      },
      lang: {
        days: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
        months: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec"
        ],
      },
      uikitClasses: {
        ul: "uk-pagination",
        li: "",
        liActive: "uk-active",
        liDisable: "uk-disabled",
        button: "page-link"
      },
      Labels: {
        first: "<span uk-icon='chevron-double-left'; ratio = '1.2'></span>",
        prev: "<span uk-pagination-previous></span>",
        next: "<span uk-pagination-next></span>",
        last: "<span uk-icon='chevron-double-right'; ratio = '1.2'></span>"
      },
      examineeName: '',
      examCodeExamName: '',
      variants: [],
      reg: /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
      getExamQuestion: [],
      surDetails: [],
      surveyExamId: 0,
      deleteExamId: '',
      // localPath: 'http://localhost:8084/ubtexam/teacher',
      localPath: '/ubtexam/teacher',
      setVa: '',
      checkall: false,
      showVariant: [],
      unAttIndex: 0,
      unAtt: 0,
      link: ''
    };
  },
  created() {
  },
  mounted() {
  },
  filters: {
    moment: function (date) {
      return moment(date).format('YYYY.MM.DD HH:mm')
    }
  },
  methods: {
    deleteexamId(item) {
      this.deleteExamId = item
    },
    variantUrl(item) {
      this.$setCookie('utoken', this.getToken);
      this.$setCookie('urole', this.currentUser.authorities[0].authority);
      this.$setCookie('uorganizId', this.currentUser.organizs[0].organizId);
      let lang = this.$i18n.locale== "kr" ? "ko" : this.$i18n.locale
      window.open(this.localPath + "/" + this.link + "?lang=" + lang + "&examId=" + this.examId + "&variant=" + item, '_blank',
          'width=1440,height=880')
    },
    questionCheckUrl(link, examId, variant) {
      if (variant.length > 1) {
        this.link = link;
        this.examId = examId;
        this.showVariant = variant;
        UIkit.modal('#variant_show').show();
      } else {
        this.$setCookie('utoken', this.getToken);
        this.$setCookie('urole', this.currentUser.authorities[0].authority)
        this.$setCookie('uorganizId', this.currentUser.organizs[0].organizId)
        let lang = this.$i18n.locale== "kr" ? "ko" : this.$i18n.locale;
        window.open(this.localPath + "/" + link + "?lang=" + lang +
            "&examId=" + examId + "&variant=A", '_blank',
            'width=1440,height=880')
      }
    },
    async deletesExam() {
      try {
        const response = await ExamService.deleteExam({examId: this.deleteExamId});
        if (response.status == 200) {
          if (response.data.status == 305) {
            alert("There have student results.")
          } else {
            alert("Exam deleted!!")
          }
          this.loaddata();
        }
      } catch (error) {
        console.log(error)
      }
    },
    async nextVar(open) {
      var lecodes = [];
      const response = await ExamService.viewExamCodes(
          {
            examineeName: '',
            examCode: '',
            examId: this.examId
          }
      );
      if (response.data.status == 200) {
        this.examCodes = response.data.result.examcodes;
      }
      if (open != 'non') {
        if (this.showTable) {
          for (let i = 0; i < this.examCodes.length; i++) {
            let exams = this.examCodes[i];
            for (let j = 0; j < exams.examcodes.length; j++) {
              let it = exams.examcodes[j];
              if (it.examineeid != null) {
                lecodes.push(it)
              }
            }
          }
          this.examCodes[0].examcodes = [];
          this.examCodes[0].examcodes = lecodes;
          this.showTable = !this.showTable
        } else {
          this.showTable = !this.showTable
        }
      } else {
        for (let i = 0; i < this.examCodes.length; i++) {
          let exams = this.examCodes[i];
          for (let j = 0; j < exams.examcodes.length; j++) {
            let it = exams.examcodes[j];
            if (it.examineeid != null) {
              lecodes.push(it)
            }
          }
        }
        this.examCodes[0].examcodes = [];
        this.examCodes[0].examcodes = lecodes
      }
    },
    async viewExamCodes(item) {
      try {
        this.examCodes = [];
        this.examId = item.examId;
        this.examCodeExamName = item.examName;
        this.variants = item.variants;
        const response = await ExamService.viewExamCodes(
            {
              examineeName: '',
              examCode: '',
              examId: item.examId
            }
        );
        if (response.data.status == 200) {
          this.examCodes = response.data.result.examcodes;
          for (let i = 0; i < this.examCodes.length; i++) {
            let exams = this.examCodes[i];
            for (let ii = 0; ii < exams.examcodes.length; ii++) {
              let it = exams.examcodes[ii];
              it.selected = false;
              if (it.isOrder != false) {
                this.isOrderCount += 1;
              }
            }
          }
        }
      } catch (error) {
        console.log(error)
      }
    },
    async attachSur(id, index) {
      try {
        let item = {
          researchId: id,
          examId: this.surveyExamId
        };
        const response = await ExamService.attachSurvey(item);
        if (response.data.status == 200) {
          if (response.data.success == true) {
            this.surDetails[index].attached = !this.surDetails[index].attached;
            alert(this.$t("success"))
          }
        }

      } catch (error) {
        console.log(error)
      }
    },
    unAttModel(id, index){
      this.unAtt = id
      this.unAttIndex = index
      UIkit.modal('#askingPop').show();
    },
    async unAttachSur() {
      try {
        let item = {
          researchId: this.unAtt,
          examId: this.surveyExamId
        };
        const response = await ExamService.unAttachSurvey(item);

        if (response.data.status == 200) {
          if (response.data.success == true) {
            this.surDetails[this.unAttIndex].attached = !this.surDetails[this.unAttIndex].attached;
            alert(this.$t("success"))
          }
        }
      } catch (error) {
        console.log(error)
      }
    },
    removeExam(item) {
      this.question.splice(item, 1);
    },
    searchDefault() {
      window.location.reload()
    },
    searchFilter() {
      this.loaddata(this.currentPage);
    },
    async examControl(item) {
      this.CurrentExam = item
    },
    async sendExamId(emailAddress, examCode) {
      try {
        if (emailAddress == null || emailAddress == '') {
          alert("Please Enter Email");
          return;
        }
        if (!this.reg.test(emailAddress)) {
          alert("Please Enter Correct Email");
          return;
        }
        let item = {
          examCode: examCode,
          userEmail: emailAddress
        };
        const response = await ExamService.ExamCodeSendOne(item);

        if (response.data.status == 200) {
          alert(response.data.message)
        } else {
          alert("exam code send error")
        }
      } catch (error) {
        alert("exam code send error")
      }
    },
    selVarAll() {
      this.checkall = !this.checkall;
      this.examCodes[0].examcodes.map((item) => {
        item.selected = this.checkall
      })
    },
    selVar(item) {
      item.selected = !item.selected;
      this.examCodes[0].examcodes.map((item) => {
        if (item.selected == 'true') {
          this.checkall = true
        } else {
          this.checkall = false
        }
      })
    },
    async sendVariants() {
      try {
        let datas = [];
        for (let i = 0; i < this.examCodes[0].examcodes.length; i++) {
          let item = this.examCodes[0].examcodes[i];
          if (item.examineeid != null) {
            if (item.selected == true) {
              let obj = {
                examCode: item.examcode,
                userId: item.examineeid,
                variant: this.setVa
              };
              datas.push(obj)
            }
          } else {
            alert("Examinee email not find.")
          }
        }
        const response = await ExamService.variantSend({datas: datas});
        if (response.data.status == 200) {
          alert(response.data.message);
          this.nextVar('non')
        } else {
          alert("exam code send error")
        }
      } catch (error) {
        console.log(error)
      }
    },
    async sendAllUserExamCode() {
      try {
        let datas = [];
        for (let i = 0; i < this.examCodes[0].examcodes.length; i++) {
          let item = this.examCodes[0].examcodes[i];
          if (this.reg.test(item.examineeemail)) {
            let obj = {
              email: item.examineeemail,
              examcode: item.examcode
            };
            datas.push(obj)
          }
        }

        if (datas.length == 0) {
          alert("Email not found");
          return;
        }
        const response = await ExamService.ExamCodeSendAll(datas);
        if (response.data.status == 200) {
          alert(response.data.message)
        } else {
          alert("exam code send error")
        }
      } catch (error) {
        alert("exam code send error")
      }
    },
    async examStatusChange(item) {
      try {
        let hell = {
          examId: this.CurrentExam.examId,
          examStatus: item
        };
        const response = await ExamService.getExamStatusChange(hell);
        if (response.data.status == 200) {
          this.examStatus = response.data.result;
          alert("exam status is changed to " + item);
          window.location.reload(true);
        } else {
          alert(" Exam status error ")
        }
      } catch (error) {
        console.log(error)
      }
    },
    async loaddata(page) {
      try {

        if (page > 0) {
          page = page - 1;
        }

        let sDates = ''
        let eDates = ''

        if (this.exam.startDate != '')
        {
          var dd = new Date(this.exam.startDate),
              monthd = '' + (dd.getMonth() + 1),
              dayd = '' + dd.getDate(),
              yeard = dd.getFullYear();

          if (monthd.length < 2)
            monthd = '0' + monthd;
          if (dayd.length < 2)
            dayd = '0' + dayd;

          // this.exam.startDate = [yeard, monthd, dayd].join('-');
          sDates = [yeard, monthd, dayd].join('-');
        }

        if (this.exam.endDate != '')
        {
          var d = new Date(this.exam.endDate),
              month = '' + (d.getMonth() + 1),
              day = '' + d.getDate(),
              year = d.getFullYear();

          if (month.length < 2)
            month = '0' + month;
          if (day.length < 2)
            day = '0' + day;

          // this.exam.endDate = [year, month, day].join('-');
          eDates = [year, month, day].join('-');
        }

        let item = {
          page: page,
          examId: this.exam.examId,
          examName: this.exam.examName,
          startDate:  sDates,
          endDate:  eDates,
          examType: this.exam.examType,
          examFee: this.exam.examFee,
        };
        const response = await ExamService.getExamList(item);

        if (response.status == 200) {
          this.examDetail = response.data.content;
          this.pages = response.data.totalPages;

          if (!this.examDetail.length) {
            this.isLoading = false;
          }
        }
      } catch (error) {
        this.error = error.response.data.message;
        this.scrollToTop();
      }
    },
    async surveyList(item) {
      try {
        this.surveyExamId = item;
        const response = await ResearchService.getSurveyList({examId: item});
        if (response.data.content.length > 0) {
          this.surDetails = response.data.content;
          UIkit.modal('#attach_modal').show();
        } else {
          alert("Survey not found");
        }
      } catch (error) {
        console.log(error);
      }
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    onChange: function () {
      this.loaddata(this.currentPage);
    },
    async getcountries() {
      try {
        const response = await UserService.getCountries();
        this.countries = response.data
      } catch (error) {
        this.error = error.response.data.error
      }
    },
    select() {
      this.selected = [];
      if (!this.selectAll) {
        for (let item in this.surDetails) {
          this.selected.push(this.surDetails[item].id);
        }
      }
    },
    async surveyDetail(id) {
      try {
        const response = await ResearchService.getSurveyDetails(id);
        this.surDetails = response.data.result.researchDetail;
        if (response.data.status == 200) {
          console.log("lslsls")
          console.log(this.surDetails);
        }
      } catch (error) {
        console.log(error);
      }
    }
  },
}
</script>
<style scoped>
.questionDialog {
  width: 800px;
  border-radius: 15px;
}

.userQuestionView {
  height: 600px;
  overflow: auto;
}

.examCodeView {
  height: 600px;
  overflow-y: auto;
  overflow-x: hidden;
}

.answers >>> p {
  margin-bottom: 0px;
  margin-top: 8px;
}

.questionsSee {
  padding-left: 10px;
  border-bottom: 1px solid #000;
}

.questionsSee >>> p {
  font-size: 18px;
  color: #000;
  margin-bottom: 10px;
}

.onOptions {
  color: #20bf6b;
  border-color: #20bf6b;
  font-weight: 500;
}

.optionsNumber {
  font-size: 1.2em;
  font-weight: 800;
  margin-left: 5px;
}

.optionsNumber p {
  margin-bottom: 0px !important;
}

.date-picker {
  width: 48%;
  height: 0px;
}
p.control {
  font-size: medium;
}

.uk-margin-auto-vertical {
  border-radius: 15px;
  border: 1px solid black;
}

.example_a {
  width: 180px;
  color: #7C7877 !important;
  text-transform: uppercase;
  background: #ffffff;
  padding: 20px;
  border: 4px solid #7C7877 !important;
  border-radius: 6px;
  display: inline-block;
  transition: all 0.3s ease 0s;
}

.example_a:hover {
  color: #7C7877 !important;
  border-radius: 50px;
  border-color: #7C7877 !important;
  transition: all 0.3s ease 0s;
}

.example_b {
  width: 180px;
  color: #20bf6b !important;
  text-transform: uppercase;
  background: #ffffff;
  padding: 20px;
  border: 4px solid #20bf6b !important;
  border-radius: 6px;
  display: inline-block;
  transition: all 0.3s ease 0s;
}

.example_b:hover {
  color: #20bf6b !important;
  border-radius: 50px;
  border-color: #20bf6b !important;
  transition: all 0.3s ease 0s;
}

.example_c {
  width: 180px;
  color: #f15c5c !important;
  text-transform: uppercase;
  background: #ffffff;
  padding: 20px;
  border: 4px solid #f15c5c !important;
  border-radius: 6px;
  display: inline-block;
  transition: all 0.3s ease 0s;
}

.example_c:hover {
  color: #f15c5c !important;
  border-radius: 50px;
  border-color: #f15c5c !important;
  transition: all 0.3s ease 0s;
}

.uk-table-small td {
  padding: 2px 3px;
  vertical-align: middle;
}

.uk-label {
  padding: 0px 5px;
}

.example_d {
  width: 180px;
  color: #333030 !important;
  text-transform: uppercase;
  background: #ffffff;
  padding: 20px;
  border: 4px solid #333030 !important;
  border-radius: 6px;
  display: inline-block;
  transition: all 0.3s ease 0s;
}

.example_d:hover {
  color: #333030 !important;
  border-radius: 50px;
  border-color: #333030 !important;
  transition: all 0.3s ease 0s;
}

.options {
  width: 25px;
  height: 25px;
  border: 1px solid #000;
  border-radius: 50%;
  margin-left: 20px;
}

.optionNum {
  height: 35px;
  width: 35px;
  border: 1px solid #000;
  border-radius: 50%;
  padding: 0px;
  font-size: 20px;
  color: rgb(1, 176, 255);
  text-align: center;
  font-weight: 900;
}


.isorder {
  width: 80px;
  height: 25px;
  padding: 3px;
  margin: 4px;
}

.qList h3 {
  height: 30px;
  margin-right: 20px;
  padding-top: 10px;
  font-size: 1.3em;
  font-weight: 900;
  color: rgb(1, 176, 255);
  padding-left: 15px;
}


.qList h4 {
  height: 30px;
  margin-right: 20px;
  padding-top: 10px;
  font-size: 1.3em;
  font-weight: 900;
  color: rgb(1, 176, 255);
  padding-left: 15px;
}

.uk-table th {
  color: #fff;
}

.qbtn_d {
  width: 120px;
  background-color: #01b0ff;
  border: 1px solid #fff;
  display: inline-block;
  padding: 0px 10px;
  color: #fff;
  font-weight: 500;
  border-bottom-left-radius: 1em;
  border-top-right-radius: 1em;
  border-top-left-radius: 1em;
  border-bottom-right-radius: 1em;
  min-width: 30px;
  text-align: center;
  text-transform: capitalize;
}
.att{
  background-color: #fff;
  color: #01b0ff;
}

.qtitle {
  border: 1px solid #dee2e6 !important;
  vertical-align: middle;
  font-size: 14px !important;
  font-weight: 500;
  height: 33px;
  /* background: #f2f2f2; */
}

.qsubTitle {
  border: 1px solid #dee2e6 !important;
  height: 33px;
}

.qtitles {
  border-top: 3px solid rgb(1, 176, 255) !important;
  border-left: 1px solid #dee2e6 !important;
  border-bottom: 1px solid #dee2e6 !important;
  border-right: 1px solid #dee2e6 !important;
  vertical-align: middle;
  font-size: 14px !important;
  font-weight: 500;
  height: 33px;
  /* background: #f2f2f2; */
}

.qsubTitles {
  border-top: 3px solid rgb(1, 176, 255) !important;
  border-left: 1px solid #dee2e6 !important;
  border-bottom: 1px solid #dee2e6 !important;
  border-right: 1px solid #dee2e6 !important;
  height: 33px;
}
.qbtn_b {
  background-color: rebeccapurple;
  border: 1px solid #fff;
  display: inline-block;
  padding: 0px 10px;
  color: white;
  font-weight: 500;
  border-bottom-left-radius: 1em;
  border-top-right-radius: 1em;
  border-top-left-radius: 1em;
  border-bottom-right-radius: 1em;
  min-width: 30px;
  text-transform: capitalize;
  text-align: center
}
.qbtn_q {
  background-color: white;
  border: 1px solid rgb(1, 176, 255);
  display: inline-block;
  padding: 0px 10px;
  color: rgb(1, 176, 255);
  font-weight: 500;
  border-bottom-left-radius: 1em;
  border-top-right-radius: 1em;
  border-top-left-radius: 1em;
  border-bottom-right-radius: 1em;
  min-width: 30px;
  text-transform: capitalize;
  text-align: center
}


.qbtn_b:hover {
  color: rebeccapurple;
  background-color: #fff;
  border: 1px solid rebeccapurple;
  box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24), 0 17px 50px 0 rgba(0, 0, 0, 0.19);
}

.qbtn_d:hover {
  background-color: #fff !important;
  color: rgb(1, 176, 255);
  border-color: rgb(1, 176, 255);
  box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24), 0 17px 50px 0 rgba(0, 0, 0, 0.19);
}
.list-label {
  padding: 12px 5px;
}

.uk-button-hover:hover {
  background: #0d9df9;
  color: #fff;
  border-color: #fff;
}
</style>
